const _window = $(window);
const body = $('body');
import {goToByScroll} from './go-to-by-scroll';


/**
 * Toggle accordions
 */
$('.accordion__header').on('click', function (e) {

	$('.tcon', $(this)).toggleClass('tcon-transform');

	$(this).parent().toggleClass('active');

	let content = $(this).next();

	content.slideToggle(function () {
		const swiperContainer = content[0].querySelector('.swiper-container');

		if (swiperContainer !== null)
		{
			swiperContainer.swiper.update();
		}
	});
});


/**
 * Open hidden fields when the e-mail field in the "Maichimp for Wordpress" wigdet gains focus
 */
$('input[type=email]', '#site-footer .mc4wp-form').on('focus', function () {
	$('.hidden-fields', '#site-footer .mc4wp-form').slideDown();
});


let sezioneSettoriTimer;
const sezioneSettoriDelay = 200;

const sezioneSettori = $('.sezione-settori');
const sezioneSettoriBackgrounds = $('.sezione-settori__backgrounds', sezioneSettori);

sezioneSettori.find('.sezione-settori__item')
	.on('mouseenter', function () {

		clearTimeout(sezioneSettoriTimer);

		let index = $(this).parent().index() + 1;

		sezioneSettoriTimer = setTimeout(function () {

			sezioneSettori.addClass('active');

			sezioneSettoriBackgrounds.find('img.active').removeClass('active');
			sezioneSettoriBackgrounds.find('img:nth-child(' + index + ')').addClass('active');

		}, sezioneSettoriDelay);

	})
	.on('mouseleave', function () {

		clearTimeout(sezioneSettoriTimer);

		sezioneSettoriTimer = setTimeout(function () {

			sezioneSettori.removeClass('active');

		}, sezioneSettoriDelay);
	});


const elementsToAnimate = gsap.utils.toArray('.page-template-page-settori .settore')

elementsToAnimate.forEach(function (item, i) {

	gsap.to(item, {
		ease:          "power1.out",
		startAt:       {
			translateY: '150px',
		},
		translateY:    0,
		scrollTrigger: {
			trigger: item,
			start:   "top 100%",
			end:     "top 30%",
			scrub:   0.5,
			//markers: true
		},
	});
});



/**
 * Timeline
 */

const timeline = $('.timeline');

if (timeline.length > 0)
{
	const timelineDelay = 800;
	const timelineItems = $('.timeline-item', timeline);

	let i = 0;

	function advanceTimeline(step, loop = true) {

		if (typeof step !== 'undefined')
		{
			i = step;
		}

		let index = i % (timelineItems.length + 1);

		timeline.attr('data-fill', index);

		timelineItems.filter(':nth-child(-n + ' + index + ')').addClass('timeline-item--active');
		timelineItems.filter(':nth-child(n + ' + (index + 1) + ')').removeClass('timeline-item--active');

		i++;

		if (index < timelineItems.length && loop)
		{
			setTimeout(advanceTimeline, timelineDelay);
		}
	}

	ScrollTrigger.create({
		trigger: timeline[0],
		start:   'top center',
		end:     'bottom top',
		//markers: true,
		onEnter:     () => advanceTimeline(0),              // start from the beginning and continue loop
		onEnterBack: () => advanceTimeline(0),              // start from the beginning and continue loop
		onLeave:     () => advanceTimeline(0, false), // reset state
		onLeaveBack: () => advanceTimeline(0, false)  // reset state
	});
}



/**
 * FAQ cards
 */

$('.card-faq__toggle').on('click', function (e) {
	// don't change class when the user clicks on the plus/minus icon
	if (!$(e.target).hasClass('tcon'))
	{
		$('.tcon', $(this).parent()).toggleClass('tcon-transform');
	}

	$(this).parent().toggleClass('active');

	let content = $(this).next();

	content.slideToggle();
}).on('mouseenter', function () {
	$(this).parent().addClass('card-faq--hover');
}).on('mouseleave', function () {
	$(this).parent().removeClass('card-faq--hover');
});


$('.card-faq__icon').on('click', function (e) {
	$('.tcon', $(this)).toggleClass('tcon-transform');

	$(this).parent().toggleClass('active');

	let content = $(this).prev();

	content.slideToggle();
}).on('mouseenter', function () {
	$(this).parent().addClass('card-faq--hover');
}).on('mouseleave', function () {
	$(this).parent().removeClass('card-faq--hover');
});



/**
 * Go to by scroll buttons
 */

$('.hero-home__scroll-down').on('click', function () {
	goToByScroll($('.sezione-settori'));
});

$('.hero__scroll-down', '.page-template-page-settore').on('click', function () {
	goToByScroll($('.intro'));
});

$('.hero__scroll-down', '.page-template-page-riparazione').on('click', function () {
	goToByScroll($('.intro'));
});

$('.hero__scroll-down', '.page-template-page-diventa-centro').on('click', function () {
	goToByScroll($('.intro'));
});

$('.go-to-faq').on('click', function () {
	goToByScroll($('.faqs'));
});



/**
 * Marquee clients
 */

$('.clients__marquee-inner').each(function () {
	$(this).append($(this).contents().clone());
});



/**
 * Toggle contact tools
 */

$('.contact-tools-toggle').on('click', function (e) {
	e.preventDefault();

	const contactToolsToggle = $(this);

	// open/close mobile menu
	body.toggleClass('contact-tools-open');
	contactToolsToggle.toggleClass('active');

	$('body').on('click.closeMenuProdotti', function (event) {
		const container = $(".contact-tools");

		// if the target of the click isn't the container nor a descendant of the container
		if (!contactToolsToggle.is(event.target) && contactToolsToggle.has(event.target).length === 0 && !container.is(event.target) && container.has(event.target).length === 0)
		{
			body.removeClass('contact-tools-open');
			contactToolsToggle.removeClass('active');
			$('body').off('click.closeMenuProdotti');
		}
	});
});

$('.contact-tools__close').on('click', function (e) {
	e.preventDefault();

	const contactToolsToggle = $(this);

	body.removeClass('contact-tools-open');
	contactToolsToggle.removeClass('active');
	$('body').off('click.closeMenuProdotti');
});



$('.mc4wp-form .btn').on('click', function () {
	if ($('input[name=AGREE_TO_TERMS]').prop('checked') !== true)
	{
		$('.mc4wp-form .privacy label').addClass('error');
	}
	else
	{
		$('.mc4wp-form .privacy label').removeClass('error');
	}
});


$('input[name=AGREE_TO_TERMS]').on('click', function () {
	if ($(this).prop('checked') !== true)
	{
		$('.mc4wp-form .privacy label').addClass('error');
	}
	else
	{
		$('.mc4wp-form .privacy label').removeClass('error');
	}
});


/**
 * Change language on click on toggle
 */
$('.language-switcher-wrapper .toggle-control').on('click', function () {
	$(this).toggleClass('toggle-control--active');

	setTimeout(function () {
		location.href = $('.lang-selector__lang:not(.lang--active):eq(0)').attr('href');
	}, 300);
});



/**
 * ON LOAD
 */

_window.on('load', function (event) {

});



/**
 * ON RESIZE
 */

_window.on('resize', function (event) {

	if (event.target === window)
	{
		// trigger resizeEnd event only when the window resizing ends
		if (this.resizeTO)
		{
			clearTimeout(this.resizeTO);
		}
		this.resizeTO = setTimeout(function () {
			$(this).trigger('resizeEnd');
		}, 200);
	}

});



/**
 * ON RESIZE END
 */

// do something when the window resizing ends
_window.on('resizeEnd', function () {

});



/**
 * ON SCROLL
 */
/*window.addEventListener('scroll', function() {
});*/